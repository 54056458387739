import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChangeValueExport, PumpScheduleTestResultBase } from '../stage-test-results/pump-schedule-test-result-base.component';
import { IFactTestName, StaticGelStrengthField, TestStatusDescription, TestTableName, TestTableNameTestNamesMapping } from '../stage-test-results/stage-test-results.constant';
import { PumpScheduleStageTest, PumpScheduleStageTestTable, TestInfo } from '../../../../../../../libs/models/ifact/ifacts-request-tests';
import { IFactsAvailableSlurry } from '../../../../../../../libs/models/ifact/ifacts-available-slurry';

@Component({
  selector: 'static-gel-strength-result',
  templateUrl: './static-gel-strength-result.component.html',
  styleUrls: ['./static-gel-strength-result.component.scss']
})
export class StaticGelStrengthResultComponent extends PumpScheduleTestResultBase implements OnInit {
  @Input() set testTables(value: PumpScheduleStageTestTable[]) {
    super.initTestTable(value);
  };
  @Input() set availableSlurries(value: IFactsAvailableSlurry[]) {
    super.initAvailableTestSlurries(value);
  }

  @Input() canEdit: boolean;
  @Output() onChangeSlurryNo = new EventEmitter<ChangeValueExport>();
  constructor() {
    super(TestTableName.StaticGelStrength);
  }

  ngOnInit(): void {
  }

  changeSlurryNo(event: any) {
    super.changeSlurryNo(event.value);
    this.onChangeSlurryNo.emit(super.getChangeValueExport());
  }

  get zeroGel(): any {
    if (this.tests && this.tests.length > 0) {
      const test = this.staticGelTest;

      // Check if test was found and contains the required results
      if (test && test.pumpScheduleStageTestFields.length > 0) {
        const timeCSGSResult = this.getField(test, StaticGelStrengthField.TimeCSGS);
        const time100Result = this.getField(test, StaticGelStrengthField.Time100);

        // Return Time CSGS if not null
        if (timeCSGSResult != undefined && timeCSGSResult !== null) {
          return timeCSGSResult;
        } else if (test.testName === IFactTestName.StaticGelStrengthSGSA) {
          // If the test is StaticGelStrengthSGSA, return Time 100 lb/100ft2
          return time100Result || undefined;
        } else if (time100Result !== null) {
          // If the test is not StaticGelStrengthSGSA, return Time 100 lb/100ft2 if not null
          return time100Result;
        }
      }
    }

    // Return undefined if data is not found
    return undefined;
  }

  get cGSP(): any {
    if (this.tests && this.tests.length > 0) {
      const test = this.staticGelTest;

      // Check if test was found and contains the required results
      if (test && test.pumpScheduleStageTestFields.length > 0) {
        const cgspResult = this.getField(test, StaticGelStrengthField.CGSP);
        const time500Result = this.getField(test, StaticGelStrengthField.Time500);
        const timeCSGSResult = this.getField(test, StaticGelStrengthField.TimeCSGS);
        const time100Result = this.getField(test, StaticGelStrengthField.Time100);
        const initialSGSRef = this.getField(test, StaticGelStrengthField.InitialSGSRef);
        const cgspMACSII = this.getField(test, StaticGelStrengthField.CGSPMACSII);

        // Return CGSP or 100-500 lb/100ft2 if not null
        if (cgspResult != undefined && cgspResult !== null && (test.testName === IFactTestName.StaticGelStrength || test.testName === IFactTestName.StaticGelStrengthSGSA)) {
          return cgspResult;
        }

        // Return CGSP or 100-500 lb/100ft2 if not null
        if (cgspMACSII != undefined && cgspMACSII !== null && test.testName === IFactTestName.StaticGelStrengthMACSII) {
          return cgspMACSII;
        }
        // If CGSP is null, check Time CSGS and Time 500 lb/100ft2
        else if (timeCSGSResult != undefined && timeCSGSResult !== null && time500Result !== null) {
          // Calculate the difference between Time 500 lb/100ft2 and Time CSGS
          const time500 = time500Result.split(":").map(Number);
          const timeCSGS = timeCSGSResult.split(":").map(Number);

          let differenceHours = time500[0] - timeCSGS[0];
          let differenceMinutes = time500[1] - timeCSGS[1];

          // Adjust for negative minutes
          if (differenceMinutes < 0) {
            differenceHours--;
            differenceMinutes += 60;
          }

          return this.formatTime(differenceHours.toString()) + ':' + this.formatTime(differenceMinutes.toString());
        }
        // If both CGSP and Time CSGS are null and test is StaticGelStrengthSGSA, calculate Transition time
        else if (test.testName === IFactTestName.StaticGelStrengthSGSA && time500Result && time100Result && time500Result !== null) {
          if (initialSGSRef !== null)
            return '';

          // Calculate the difference between Time 500 lb/100ft2 and Time 100 lb/100ft2
          const time500 = time500Result.split(":").map(Number);
          const time100 = time100Result.split(":").map(Number);

          let differenceHours = time500[0] - time100[0];
          let differenceMinutes = time500[1] - time100[1];

          // Adjust for negative minutes
          if (differenceMinutes < 0) {
            differenceHours--;
            differenceMinutes += 60;
          }

          return this.formatTime(differenceHours.toString()) + ':' + this.formatTime(differenceMinutes.toString());
        }
      }

      // Return undefined if data is not found
      return undefined;
    }
  }

  formatTime(time: string): string {
    return time.padStart(2, '0');
  }
  get staticGelTest(): PumpScheduleStageTest {
    if (this.tests.length === 1 && this.tests[0]) {
      return this.tests[0];
    }

    const validatedOrFinishedTests = this.tests.filter(this.isTestFinishedOrValidated);

    if (validatedOrFinishedTests.length === 1 && validatedOrFinishedTests[0]) {
      return validatedOrFinishedTests[0];
    }

    return null;
  } 

  get testDescription() {
    return !this.staticGelTest ? '' : this.staticGelTest?.testStatusDescription == 'Tested' ? 'Requested to Lab' : this.staticGelTest?.testStatusDescription;
  } 
}
