import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChangeValueExport, PumpScheduleTestResultBase } from '../stage-test-results/pump-schedule-test-result-base.component';
import { PumpScheduleStageTest, PumpScheduleStageTestTable } from 'libs/models/ifact/ifacts-request-tests';
import { RheologyField, TestTableName } from '../stage-test-results/stage-test-results.constant';
import { IFactsAvailableSlurry } from 'libs/models/ifact/ifacts-available-slurry';

@Component({
  selector: 'rheology-test-result',
  templateUrl: './rheology-test-result.component.html',
  styleUrls: ['./rheology-test-result.component.scss']
})
export class RheologyTestResultComponent extends PumpScheduleTestResultBase implements OnInit {
  @Input() set testTables(value: PumpScheduleStageTestTable[]) {
    super.initTestTable(value);
  };
  @Input() set availableSlurries(value: IFactsAvailableSlurry[]) {
    super.initAvailableTestSlurries(value);
  }
  @Input() canEdit: boolean;
  @Output() onChangeSlurryNo = new EventEmitter<ChangeValueExport>();
  @Output() onDeleteTest = new EventEmitter<ChangeValueExport>();

  RheologyField = RheologyField;

  constructor() {
    super(TestTableName.Rheology);
  }

  ngOnInit(): void {
  }

  getTemperatureField(test: PumpScheduleStageTest): string {
    const temperatureFieldMapping = {
      [this.IFactTestName.NonAPIRheology]: RheologyField.TestTemperature,
      [this.IFactTestName.FYSAViscosityProfileAndGelStrength]: RheologyField.TestTemperature,
      [this.IFactTestName.APIRheology]: RheologyField.Temperature,
      [this.IFactTestName.Bob2Rheology]: RheologyField.Temperature,
      [this.IFactTestName.MixRheology]: RheologyField.Temperature,
    }

    return super.getField(test, temperatureFieldMapping[test.testName]);
  }

  changeSlurryNo(event: any) {
    super.changeSlurryNo(event.value);
    this.onChangeSlurryNo.emit(super.getChangeValueExport());
  }

  deleteTest(test: PumpScheduleStageTest) {
      super.deleteTest(test);
      this.onDeleteTest.emit(super.getChangeValueExport());
  }
}
