export enum ControlpointSectionValidate {
  JobHeader = 0,
  ICemFile = 1,
  JobObjective = 2,
  JobProgram = 3,
  PumpSchedule = 4,
  LocalWellReview = 5,
  JobObjectiveSecond = 6,
  JobTechnicalParameter = 7,
  CasingEquipment = 8,
  JobPlacementTime = 9,
  Density = 10,
  CriticalWellReview = 11,
  criticalWellNumber = 12,
  reviewCompletionStatement = 13,
  EventVolumeCement = 14,
  EventVolumeSpacer = 15,
  SlaryDensity = 16,
  LinerJob = 17,
  JobObjectiveThird = 18,
  isLessonsReviewed = 19,
  JobSurfaceEquipment = 20,
  IsLessonsLearnedUpdated = 21,
  IsSomeLessonLearnedModifiedInAppian = 22,
  JobHeaderCountry = 23,
  JobHeaderState = 24,
  JobHeaderRigLocationType = 25,
  RegulatoryLesson = 26,
  LessonsUpdated = 27
}

export enum Controlpoint2SectionValidate {
  JobPlacementTime = 0,
  LoadOutVolume = 1,
  ChangeLoadOutVolume = 2,
  IsWellStatic = 3,
  CustomerValue = 4,
  HandOver = 5,
  AllEquipmentPrepared = 6,
  AllSurfaceEquipmentPrepared = 7,
  isLessonsReviewed = 8,
  SideItemsQuantity = 9,
  SideItemsPlant = 10,
  JobSurfaceEquipment = 11,
  CasingEquipment = 12,
  RequestFloatsBeAutofilled = 13,
  IsWellStaticLcm = 16,
  ThreadCompatibilityConfirmed = 17,
  IDODCompatibilityConfirmed = 18,
  PressureCompatibilityConfirmed = 19,
  TemperatureCompatibilityConfirmed = 20,
  CasingWeightCompatibilityConfirmed = 21,
  TemperatureCompatibilityComment = 22,
  PressureCompatibilityComment = 23,
  ThreadCompatibilityComment = 24,
  HasSurfaceEquipment = 25,
  SideItemsQuantityUOM = 26,
  CompatibilityCheck = 27,
  CasingEquipmentTools = 28,
  RegulatoryLesson = 29,
  LessonsUpdated = 30
}

export enum Controlpoint4SectionValidate {
  JobObjectiveStatus = 0,
  ICemFileRealTime = 1,
  SaleOrderLinked = 2,
  PumpCementActualVolumes = 3,
  PumpCementActualRates = 4,
  PumpCementActualDuration = 5,
  ControlPoint1 = 6,
  ControlPoint2 = 7,
  ControlPoint3 = 8,
  ActualJobDate = 9,
  PostJobReport = 10,
  ActualDensity = 11,
  KPIInfo = 12,
  BomTypeSelected = 13,
  BomTypeValid = 14,
  CESalesOrderLinked = 15,
  NonCementingSalesOrderExplained = 16,
  HasUsedSurfaceEquipment = 17,
}

export const Controlpoint4SectionValidateMessage = {
  JobObjectiveStatus: `Response must be provided for all objectives before submission. Any Unmet Objectives require a comment.`,
  ICemFileRealTime: `A realtime file must have been uploaded (note that this might be an .rtd file), or the question about no realtime data available must be checked.`,
  SaleOrderLinked: `The job must be linked to a Sales Order.`,
  PumpCementActualVolumes: `Actual Volumes must be entered for all Cements in the Pump Schedule.`,
  PumpCementActualRates: `Actual Rate must be entered for all non shutdown events in the Pump Schedule.`,
  PumpCementActualDuration: `Actual Duration must be entered for all shutdown events in the Pump Schedule.`,
  ControlPoint1: `Control Point 1 must also be complete.`,
  ControlPoint2: `Control Point 2 must also be complete.`,
  ControlPoint3: `Control Point 3 must also be complete.`,
  ActualJobDate: `Actual Job Date is invalid.`,
  PostJobReport: `Post-Job Reports must be uploaded.`,
  ActualDensity: `Actual Densities must be entered where indicated for all stages in the Pump Schedule.`,
  KPIInfo: `Key Performance Indicators must also be complete.`,
  BomTypeSelected: 'BOM Type is required.',
  BomTypeValid: 'Invalid Sales Order: SAP BOM Type does not match VIDA BOM Type. Select another BOM Type or change Sales Order.',
  CESalesOrderLinked: 'The job must be linked to a CE Sales Order.',
  NonCementingSalesOrderExplained: 'Explanation required if Sales Order is Not a Cementing Sales Order.',
  CasingEquipment: `If the tool failed, the Category of failure and Comment fields should be filled in (Casing Equipment section)`,
  HasUsedSurfaceEquipment: 'There must be at least one item used for Surface Equipment.',
  TotalPumpingHours: 'No Pumping Hours defined in KPI Metrics', 
  IdleTime: 'Idle Time is negative (Depart Location minus Arrive on Location minus Pumping hours)'

};

export const Controlpoint2SectionValidateMessage = {
  JobPlacementTime: `Thickening Time is required for every cement stage. Thickening time must be greater than Job Placement Time (Pump Schedule section)`,
  LoadOutVolume: `must be entered for every non-mud stage (Pump Schedule section)`,
  HandOver: `Supervisor Name, Communication Type and Date/Time are required fields (Handover section)`,
  AllEquipmentPrepared: `All Equipment Prepared is a required field and must be set to yes or supply a comment/justification (Equipment section)`,
  ChangeLoadOutVolume: `Loadout Quantities have not been updated.`,
  IsWellStatic: `"Is the Well Static?" question must be answered Yes or supply a Well Condition Comments if the answer is No (Well Condition Verification section)`,
  CustomerValue: `All Customer Values (MD/TVD/Mud Weight) in Well Condition Verification must be filled for completion.`,
  SideItemsQuantity: 'The quantity field must be filled in for all Side Items (Side Items section)',
  SideItemsQuantityUOM: 'The unit of measure must be selected for all Side Items (Side Items section)',
  CasingEquipment: `Not all Casing Equipment check sheets uploaded (Casing Equipment section)`,
  SideItemsPlant: `A plant must be set for each side item (Side Items section)`,
  AllSurfaceEquipmentPrepared: 'All Equipment Prepared is a required field and must be set to yes or supply a comment/justification (Surface Equipment section)',
  DuplicateSurfaceEquipment: 'Surface Equipment can\'t be duplicate (Surface Equipment section)',
  EmptySurfaceEquipment: 'Surface Equipment must be selected (Surface Equipment section)',
  isLessonsReviewed: `Missing Lessons Learned confirmation is incomplete (Lessons Learned section)`,
  RegulatoryLesson: `Regulatory Lessons Learned must have Notes populated and be signed`,
  LessonsUpdated: `In Lessons Learned Edit Page, Lessons must be updated at least once before CP 2 can be completed`,
  BomTypeSelected: 'BOM Type is required.',
  SaleOrderLinked: `The job must be linked to a Sales Order.`,
  IsWellStaticLcm: `"Is Impact of LCM on float equipment considered?" question must be answered Yes or supply a Comment if the answer is No (Well Condition Verification section)`,
  CustomerValueNoCMT: `All Customer Values (MD/TVD) in Well Condition Verification must be filled for completion.`,
  RequestFloatsBeAutofilled: `"Did the customer request the floats to be set to autofill?" question must be answered (Casing Equipment section)`, 
  ThreadCompatibilityConfirmed: 'Missing Thread Compatibility Confirmation (Casing Equipment section)',
  IDODCompatibilityConfirmed: 'Missing ID and OD Compatibility Confirmation (Casing Equipment section)',
  PressureCompatibilityConfirmed: 'Missing Pressure Compatibility Confirmation (Casing Equipment section)',
  TemperatureCompatibilityConfirmed: 'Missing Temperature Compatibility Confirmation (Casing Equipment section)',
  CasingWeightCompatibilityConfirmed: 'Missing Casing Weight Compatibility Confirmation (Casing Equipment section)',
  TemperatureCompatibilityComment: 'Missing Temperature Compatibility Comment (Casing Equipment section)',
  PressureCompatibilityComment: 'Missing Pressure Compatibility Comment (Casing Equipment section)',
  ThreadCompatibilityComment: 'Missing Thread Compatibility Comment (Casing Equipment section)',
  DistanceToRig: 'There must be a value in the distance to rig field for all land jobs.',
  HasSurfaceEquipment: 'Missing There must be at least one item listed for Surface Equipment.',
  CasingEquipmentTools: 'At least one tool needs to be added to submit (Casing Equipment section)',
  JobPlacementTimeGreaterThenGPT: `Thickening Time must be greater than Job Placement Time (Pump Schedule/Fluid Details)`,
  JobPlacementTimeGreaterThenGPTStageJob: `Thickening Time must be greater than Job Placement Time for all pump schedules (Pump Schedule/Fluid Details)`,
};
