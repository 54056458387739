function concatArrays(...arr) {
  return arr.reduce((init1, lockedArray) => {
    return init1.concat(lockedArray);
  }, []);
}

const lockPump = ['pumpSchedule_batchMixingTime', 'pumpSchedule_targetSafetyFactor', 'pumpSchedule_shutdown', 'pumpSchedule_thickeningTime', 'pumpSchedule_specificShutdownTime', 'fluidDetailInfo_loadoutVolume', 'handOver_supervisorName', 'handOver_communication', 'handOver_comment',
  'PumpSchedule_selectedFluidId', 'FluidDetails_bulkCement', 'FluidDetails_overrideQty', 'FluidDetails_comment',
  'CallSheet_fileDescription', 'handOver_communicationDateTime', 'Fluid_testTypeId', 'Fluid_slurryTypeId', 'PumpSchedule_pumpScheduleFluidTypeId',
  'PumpSchedule_slurry', 'PumpSchedule_placementMethod', 'PumpSchedule_rate', 'PumpSchedule_volume', 'fluidDetailInfo_actualVolume', 'pumpSchedule_addNewEvent',
  'pumpSchedule_btnMudParamEdit', 'pumpSchedule_spacerMixMethod', 'fluidDetailInfo_actualDensity', 'MaterialLoadSheet_fileDescription'
];
const actualFieldPump = ['FluidDetails_actualQty', 'FluidDetails_actualQuantityUnit', 'PumpSchedule_actualRate', 'PumpSchedule_actualDuration'];
const lockFluidComplete = ['fluidDetailInfo_bulkCement'];
const lockPumpComplete = ['PumpSchedule_duration', 'PumpSchedule_topOfFluid', 'PumpSchedule_length'];
const fluidMaterial = ['Fluid_sap_material'];
const lockAddButton = ['PumpSchedule_addStage'];
const schedule = ['schedule_shutdown', 'schedule_targetSafetyFactor', 'schedule_batchMixingTime'];
const lockCP4Header = [
  'cp4_actualMd', 'cp4_saleOrderNumber', 'cp4_acutalJobDate'
];

const lockCP4RealTime = [
  'cp4_uploadRealTime', 'cp4_realTimeCheckbox', 'iCem_uploadiCemRealtime'
];

const lockCP4JobObjective = [
  'cp4_jobObjectiveMet', 'cp4_objectiveActualValue', 'cp4_objectiveComment'
];
const lockShoeTrackOption = ['pumpSchedule_batchMixingTime', 'pumpSchedule_targetSafetyFactor', 'pumpSchedule_shutdown'];
const lockShoeTrack = [...lockShoeTrackOption, 'ShoeTrack_shoeTrackLength', 'ShoeTrack_shoeTrackVolume'];

const jobObjectives = ['jobObjectives'];

const lockCP2 = ['well_info'];

const lockCP3 = ['cp3_jobSite_QA_Document', 'cp3_equipment_functionTest', 'cp3_job_safety_analysis'];

const lockJobEdit = ['job_completed'];

const lockSupplemental = ['supply_iFactsMaterialName', 'supply_Concentration', 'supply_ConcentrationUnit'];

const lockCasingSize = ['holeSize', 'casingSize', 'holeSizeExcess'];

const lockLinerJobParams = ['Liner_CirculationMethod', 'Liner_CementLength', 'Liner_IsVersaFlex'];

const cp2CompleteListTemp = [
  'fluidDetailInfo_loadoutVolume',
  'PumpSchedule_selectedFluidId', 'FluidDetails_bulkCement', 'FluidDetails_overrideQty', 'FluidDetails_comment', 'ShoeTrack_shoeTrackLength', 'ShoeTrack_shoeTrackVolume',
  'CallSheet_fileDescription', 'handOver_communicationDateTime', 'cp2_specifications_comments', 'Fluid_testTypeId', 'Fluid_slurryTypeId', 'PumpSchedule_pumpScheduleFluidTypeId',
  'PumpSchedule_slurry', 'PumpSchedule_placementMethod', 'PumpSchedule_rate', 'PumpSchedule_volume', 'JobOptions_iCemFiles', 'PumpSchedule_addStage',
  'Fluid_remove', 'pumpSchedule_addNewEvent', 'equipment_isAllEquipmentPrepared', 'equipment_allEquipmentPreparedComment', 'equipment_requiredCrossoverConnections', 'equipment_isCentralizers',
  'equipment_isPlugsDartBalls', 'equipment_isFloatEquipment', 'equipment_isPlugContainers', 'equipment_wellConnectionSize', 'equipment_wellConnectionThreads', 'equipment_plugContainerConnectionSize',
  'equipment_casingConnectionThread', 'equipment_casingEquipmentConnectionThread', 'equipment_isFunctionTestPerformed', 'equipment_minIDBoreHole', 'equipment_minIDPipe', 'equipment_maxODCasing',
  'equipment_maxODPlug', 'equipment_isDartCompatible', 'equipment_dartCompatibilityComment', 'equipment_isIDODCompatibilityConfirmed',
  'fluidDetailInfo_deadVolume', 'pumpSchedule_btnMudParamEdit', 'wellCondition_customerMD', 'wellCondition_customerTMD',
  'wellCondition_customerMudWeight', 'wellCondition_wellStatic', 'wellCondition_comments',
  'wellCondition_isCasingStretchFlag', 'wellCondition_isImpactLCMConsideredFlag', 'wellCondition_casingStretchComments',
  'wellCondition_impactLCMComments', 'pumpSchedule_spacerMixMethod', 'overrideMixingProcedure', 'job_rig_customer', 'job_rig_directions', 'job_rig_distance', 'job_rig_comments', 'plant', 'sideItems',
  'lessonsLearnedCP2', 'pumpSchedule_thickeningTime', 'pumpSchedule_specificShutdownTime'
];

const cp2PrepareItemList = ['handOver_supervisorName', 'handOver_communication', 'handOver_comment', 'handover_file', 'job_rig_customer', 'job_rig_directions', 'job_rig_distance', 'job_rig_comments' ];

const cp1JobParamsFirst = [
  'cdi_porePressure_tvd', 'cdi_porePressure_density', 'cdi_porePressure_mudWeight_density', 'cdi_porePressure_pressure', 'cdi_porePressure_mudWeight_pressure', 'cdi_porePressure_justification',
  'cdi_fractureGradient_tvd', 'cdi_fractureGradient_density', 'cdi_fractureGradient_maxEcd_density', 'cdi_fractureGradient_pressure', 'cdi_fractureGradient_maxEcd_pressure', 'cdi_fracturePressure_justification',
  'plugBase', 'cp1_jobMd', 'cp1_jobTvd', 'maxPressure'
];
const cp1JobParamsSecond = ['holeSize', 'holeSizeExcess', 'casingSize', 'cp1_bhst', 'cp1_bhct', 'lengthOfPlug'];

const cp1SubmittedList = [
  'pumpSchedule_thickeningTime', 'pumpSchedule_specificShutdownTime',
  'cp1_criticalWellReview', 'cp1_casing_equipment', 'pumpSchedule_spacerMixMethod', 'ShoeTrack_shoeTrackLength',
  'ShoeTrack_shoeTrackVolume', 'cp1_bhct_confirmation', 'iCem_uploadiCemDesign', 'cp1_specifications_comments',
    'pumpSchedule_btnMudParamEdit', 'lessonsLearnedCP1', ...cp1JobParamsFirst, ...lockLinerJobParams, ...jobObjectives, ...lockShoeTrackOption
];


const cp1ApprovedList = concatArrays(['cp1Aprroved'],cp1SubmittedList, fluidMaterial);
const cp2CompletedList = concatArrays(cp2PrepareItemList, cp2CompleteListTemp, fluidMaterial, lockFluidComplete, lockSupplemental, lockCP2, jobObjectives);
const cp2PreparedList = concatArrays(cp2CompleteListTemp, fluidMaterial, lockFluidComplete, lockSupplemental, lockCP2, jobObjectives);
const cp1Submitted_cp2PreparedList = concatArrays(cp1SubmittedList, cp2PreparedList, cp1JobParamsSecond);
const cp1Submitted_2CompletedList = concatArrays(cp2CompletedList, cp1ApprovedList, cp1SubmittedList, cp1JobParamsSecond);
const cp4CompletedList = concatArrays(['additional_comments'], lockPump, actualFieldPump, lockShoeTrack, lockCP4Header, lockCP4RealTime, lockCP4JobObjective, lockCP3, cp2CompletedList);
const jobCompleteList = concatArrays(cp1ApprovedList, cp2CompletedList, cp4CompletedList, fluidMaterial, lockFluidComplete, lockPump, actualFieldPump, lockAddButton, schedule, lockJobEdit, lockPumpComplete);

export const LockableElementStatus = {
  cpInit: [],
  cp1Submitted: cp1SubmittedList,
  cp1Approved: cp1ApprovedList,
  cp2Completed: cp2CompletedList,
  cp1Submit2Completed: cp1Submitted_2CompletedList,
  cp4Completed: cp4CompletedList,
  jobComplete: jobCompleteList,
  cp2Prepared: cp2PreparedList,
  cp1Submitted2Prepared: cp1Submitted_cp2PreparedList
};
