import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DeadVolumeFluidType, SpacerMixMethod, UnitType } from 'libs/constants';
import { isFormDirty, isFormInvalid } from 'libs/helpers';
import { MudParameterModel } from 'libs/models';
import { ValidatorSetting } from '../shared/constant';
import { CustomValidators } from '../shared/validators';

@Injectable()
export class PumpScheduleFormManager extends UntypedFormBuilder {

    public static readonly numericValidators = [
        Validators.min(ValidatorSetting.MIN_DECIMAL_LENGHT),
        Validators.max(ValidatorSetting.MAX_DECIMAL_LENGHT),
        CustomValidators.validateIsNumber,
        CustomValidators.validateNegativeNumber
    ];

    public static aloud = {
        emitEvent: true,
        onlySelf: false
    };

    public static silent = {
        emitEvent: false,
        onlySelf: true
    };

    private _scheduleForm: UntypedFormGroup;

    public clearScheduleStageFormArray() {
        const stagesArray = this._scheduleForm.controls.stages as UntypedFormArray;

        if (stagesArray) {
            stagesArray.clear()
        }
    }

    public getScheduleForm(){
        return this._scheduleForm.value
    }

    public patchAloud(form: UntypedFormGroup, value: { [key: string]: any }): void {

        form.patchValue(value, PumpScheduleFormManager.aloud);
    }

    public patchSilent(form: UntypedFormGroup, value: { [key: string]: any }): void {

        form.patchValue(value, PumpScheduleFormManager.silent);
    }

    public isDirty(): boolean {

        return isFormDirty(this._scheduleForm);
    }

    public isInvalid(): boolean {

        return isFormInvalid(this._scheduleForm);
    }

    public isDisabled(): boolean {

        return this._scheduleForm.disabled;
    }

    public isTouched(): boolean {

        return this._scheduleForm.touched;
    }

    public markAsPristine(): void {

        this._scheduleForm?.markAsPristine(PumpScheduleFormManager.aloud);
    }

    public markAsTouched(): void {
        this._scheduleForm?.markAsTouched(PumpScheduleFormManager.aloud);
        const stagesArray = this._scheduleForm?.controls.stages as UntypedFormArray;
        stagesArray?.markAllAsTouched();
    }

    public createScheduleForm(isEditable: boolean): UntypedFormGroup {

        this._scheduleForm = this.group({
            jobId: null,
            pumpScheduleId: null,
            Name: "Pump Schedule 01", //Name default is Pump Schedule 01
            controlPointType: null,
            completeMode: false,    // this is an indicator that control point is being completed. Should use other means, not form control.
            shoeTrackLength: [{
                value: null,
                disabled: false
            }, Validators.compose([
                Validators.min(ValidatorSetting.MIN_DECIMAL_LENGHT),
                Validators.max(ValidatorSetting.MAX_DECIMAL_LENGHT),
                CustomValidators.validateIsNumber
            ])],
            shoeTrackVolume: [{
                value: null,
                disabled: false
            }, Validators.compose([
                Validators.min(ValidatorSetting.MIN_DECIMAL_LENGHT),
                Validators.max(ValidatorSetting.MAX_DECIMAL_LENGHT),
                CustomValidators.validateIsNumber
            ])],
            scheduledShutdown: [{
                value: null,
                disabled: false
            }, {
                updateOn: 'blur'
            }],
            targetSafetyFactor: [{
                value: null,
                disabled: false
            }, {
                updateOn: 'blur'
            }],
            batchMixingTime: [{
                value: null,
                disabled: false
            }, {
                updateOn: 'blur'
            }],
            spacerMixMethod: [{
                value: SpacerMixMethod.MixOnTheFly,
                disabled: false
            }],
            isManuallySpacerMixMethod: [{
                value: false,
                disabled: false
            }],
            totalCOGS: [{
                value: null,
                disabled: false
            }],
            linerCirculationMethod: [{
                value: null,
                disabled: false
            }],
            linerCementLength: [{
                value: null,
                disabled: false
            }],
            isVersaFlexLiner: [{
                value: null,
                disabled: false
            }],
            stages: this.array([])
        });

        if (!isEditable) {

            this._scheduleForm.disable();
        }

        return this._scheduleForm;
    }

    public createStageForm(index: number, isEditable: boolean): UntypedFormGroup {

        const form = this.group({
            id: null,
            completeMode: false,  // this is an indicator that control point is being completed. Should use other means, not form control.
            order: index,
            syncObjectiveId: null,
            number: 0,
            pumpScheduleFluidTypeId: [{
                    value: null,
                    disabled: !isEditable
                },
                CustomValidators.requiredWithTrimming
            ],
            pumpScheduleFluidTypeName: null,
            slurry: [{
                    value: null,
                    disabled: !isEditable
                }],
            actualSlurry: [{
                value: null,
                disabled: !isEditable
            }],
            selectedFluidId: [{
                    value: null,
                    disabled: !isEditable
                }],
            fluidName: null,
            avgRate: [{
                    value: null,
                    disabled: true
                }],
            loadoutVolume: null,
            deadVolume: null,
            isManuallyDeadVolume: false,
            deadVolumeFluidType: null,
            stageWaterTotal: null,
            actualVolumePumped: [{
              value: null,
              disabled: false
            }, CustomValidators.validateIsNumber],
            actualDensity: [{
              value: null,
              disabled: false
            }, CustomValidators.validateIsNumber],
            plannedDensity: null,
            thickeningTime: null,
            specificShutdownTime: null,
            minThickeningTime: null,
            actualSafety: null,
            plannedVolume: [{
                value: null,
                disabled: true
            }],
            topOfFluid: [{
                    value: null,
                    disabled: true
                }],
            bulkCement: null,
            isBulkCement: false,
            isChangeLoadOutVolume: false,
            isChangeActualVolumePumped: false,
            isManualyThickeningTime: false,
            isRemoveable: true,
            mudParameter: this.group(new MudParameterModel()),
            slurryIdHDF: null,
            comment: [{
                    value: null,
                    disabled: !isEditable
                }],
            events: this.array([])
        });

        this._insertStageForm(index, form);

        if (!isEditable) {

            form.disable();
        }

        return form;
    }

    public stageListChanged(): void {

        this._scheduleForm.markAsTouched(PumpScheduleFormManager.silent);
        this._scheduleForm.markAsDirty(PumpScheduleFormManager.silent);
    }

    public removeStageForm(index: number): void {

        const stagesArray = this._scheduleForm.controls.stages as UntypedFormArray;
        stagesArray.removeAt(index);

        this._scheduleForm.markAsTouched(PumpScheduleFormManager.silent);
        this._scheduleForm.markAsDirty(PumpScheduleFormManager.silent);
    }

    public createStagePlacementTimeForm(): UntypedFormGroup {

        const form = this.group({
            id: null,
            stageNumber: 0,
            placementTime: 0,
            slurryType: null,
            thickeningTime: 0,
            specificShutdownTime: null,
            minThickeningTime: null,
            actualSafety: null
        });

        return form;
    }

    public createEventForm(stageIndex: number, eventIndex: number): UntypedFormGroup {

        const maxLength = ValidatorSetting.MAX_DECIMAL_LENGHT;
        const minLength = ValidatorSetting.MIN_DECIMAL_LENGHT;

        const form = this.group({
            pumpScheduleEventId: null,
            placementMethod: null,
            placementMethodName: null,
            rate: [{
                value: null,
                disabled: false
                }, Validators.compose([
                    Validators.min(minLength),
                    Validators.max(maxLength),
                    CustomValidators.validateIsNumber,
                    CustomValidators.validateNegativeNumber
            ])],
            actualRate: [{
                value: null,
                disabled: false
            }, CustomValidators.validateIsNumber],
            volume: [{
                value: null,
                disabled: false
                }, Validators.compose([
                    Validators.min(minLength),
                    Validators.max(maxLength),
                    CustomValidators.validateIsNumber
            ])],
            topOfFluid: [{
                value: null,
                disabled: true
            }],
            length: [{
                value: null,
                disabled: true
            }],
            duration: [{
                value: null,
                disabled: false
                }, Validators.compose([
                    Validators.min(minLength),
                    Validators.max(maxLength),
                    CustomValidators.validateIsNumber
            ])],
            actualDuration: [{
                value: null,
                disabled: false
            }],
            bulkCement: [{
                value: null,
                disabled: false
                }, Validators.compose([
                    Validators.min(minLength),
                    Validators.max(maxLength),
                    CustomValidators.validateIsNumber
            ])],
            order: eventIndex
        });

        this._insertEventForm(stageIndex, eventIndex, form);

        return form;
    }

    public eventListChanged(stageIndex: number): void {

        const stageForm = this._findStageForm(stageIndex);

        stageForm.markAsTouched(PumpScheduleFormManager.silent);
        stageForm.markAsDirty(PumpScheduleFormManager.silent);
    }

    public removeEventForm(stageIndex: number, eventIndex: number): void {

        const stageForm = this._findStageForm(stageIndex);

        const eventsArray = stageForm.controls.events as UntypedFormArray;
        eventsArray.removeAt(eventIndex);

        stageForm.markAsTouched(PumpScheduleFormManager.silent);
        stageForm.markAsDirty(PumpScheduleFormManager.silent);
    }

    public createFluidForm(stageIndex: number): UntypedFormGroup {

        const form = this.group({
            createdDate: null,
            id: null,
            isCementBlend: false,
            isFoam: false,
            labName: null,
            blendName: null,
            cementName: null,
            water: null,
            sackWeight: null,
            density: [{
                value: null,
                disabled: true
            }],
            waterRequirements: [{
                value: null,
                disabled: true
            }],
            stageWaterTotal: [{
                value: null,
                disabled: true
            }],
            mixFluid: [{
                value: null,
                disabled: true
            }],
            mixWater: [{
                value: null,
                disbaled: true
            }],
            yield: [{
                value: null,
                disabled: true
            }],
            foamDensity: [{
                value: null,
                disabled: true
            }],
            foamQuality: [{
                value: null,
                disabled: true
            }],
            plannedVolume: [{
                value: null,
                disabled: true
            }],
            plannedVolumeUnit: [{
                value: UnitType.LargeVolume,
                disabled: true
            }],
            loadoutVolume: [
                null,
                PumpScheduleFormManager.numericValidators
            ],
            deadVolume: [
                null,
                PumpScheduleFormManager.numericValidators
            ],
            isManuallyDeadVolume: false,
            deadVolumeFluidType: DeadVolumeFluidType.CementSlurry,
            totalBulkCement: null,
            bulkCement: [
                null,
                PumpScheduleFormManager.numericValidators
            ],
            sapMaterialDisplayName: null,
            mixVolume: null,
            materials: this.array([]),
            order: 0,
            hdfSlurryIds: [{
                value: [],
                disabled: true
            }],
            slurryTypeId: [{
                value: null,
                disabled: false
            }]
        });

        const stageForm = this._findStageForm(stageIndex);
        stageForm.addControl('fluidForm', form);

        return form;
    }

    public createMaterialForm(stageIndex: number, order: number): UntypedFormGroup {
       
        const form = this.group({
            id: null,
            fluidMaterialId: null,
            materialType: null,
            ifactMaterialName: [{
                value: null,
                disabled: true
            }],
            ifactMaterialId: null,
            concentrartion: null,
            concentrationUnit: null,
            isBlendComponent: null,
            testAmount: null,
            outputUnit: null,
            sg: null,
            plannedVolume: [{
                value: null,
                disabled: true,
            }],
            plannedVolumeUnit: [{
                value: null,
                disabled: true
            }],
            loadoutVolume: [{
                value: null,
                disabled: true
            }],
            loadoutVolumeUnit: [{
                value: null,
                disabled: true
            }],
            overrideVolume: [
                null,
                PumpScheduleFormManager.numericValidators
            ],
            overrideMixingProcedureId: null,
            sapMaterialName: null,
            sapMaterialNumber: null,
            blendBulkDensity: null,
            cementBulkDensity: null,
            mixWater: null,
            sackWeight: null,
            countBlendComponent: 0,
            notExistedSAPMaterial: false,
            hdfMaterialId: null,
            actualQty: [
                null,
                PumpScheduleFormManager.numericValidators
            ],
            dryVolume: null,
            mixingProcedureValue: null,
            order: 0,
            plannedScope3Co2e: null,
            actualScope3Co2e: null
        });

        this._insertMaterialForm(stageIndex, order, form);

        return form;
    }

    public clearMaterialForms(stageIndex: number): void {

        const fluidForm = this._findFluidForm(stageIndex);
        const materialsForms = fluidForm?.controls.materials as UntypedFormArray;
        materialsForms?.clear();
    }

    public isStageFluidDirty(stageIndex: number): boolean{
        return (this._scheduleForm.controls.stages as UntypedFormArray).at(stageIndex)?.dirty; 
    }

    private _findStageForm(stageIndex: number): UntypedFormGroup {

        const stagesArray = this._scheduleForm.controls.stages as UntypedFormArray;
        return stagesArray.at(stageIndex) as UntypedFormGroup;
    }

    private _findFluidForm(stageIndex: number): UntypedFormGroup {

        const stageForm = this._findStageForm(stageIndex);
        return stageForm?.controls.fluidForm as UntypedFormGroup;
    }

    private _insertStageForm(index: number, form: UntypedFormGroup): void {

        const stagesArray = this._scheduleForm.controls.stages as UntypedFormArray;
        stagesArray.insert(index, form);
    }

    private _insertEventForm(stageIndex: number, eventIndex: number, form: UntypedFormGroup): UntypedFormGroup {
 
        const stageForm = this._findStageForm(stageIndex);

        const eventsArray = stageForm.controls.events as UntypedFormArray;
        eventsArray.insert(eventIndex, form);

        return stageForm;
    }

    private _insertMaterialForm(stageIndex: number, materialIndex: number, form: UntypedFormGroup): void {
 
        const fluidForm = this._findFluidForm(stageIndex);
        const materialsArray = fluidForm?.controls.materials as UntypedFormArray;
        materialsArray?.insert(materialIndex, form);
    }
}
