import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

export class JobCasingEquipmentCP2UpdateModel {
    id: string;
    dataSource: string;
    numberOfItems: number;
    sapMaterialDescription: string;
}

export class JobCasingEquipmentCP2Model extends JobCasingEquipmentCP2UpdateModel {
    sapMaterialNumber: string;
    toolId: string;
    count: number;
    productCode: string;
    productGroup: string;
    productName: string;
    productFamily: string;
    className: string;
    casingWeight: string;
    casingGrade: string;
    casingThread: string;
    sapMaterialDescription: string;
    classDescription: string;
    casingSize: string;
    status: string;
    numberOfItems: number;
    numberOfItemsShipped: number;
    maxPressure: number;
    collapsePressure: number;
    burstPressure: number;
    maxTemperature: number;
    isMaxPressureNA: boolean;
    isMaxTemperatureNA: boolean;
    isNewlyAdded: boolean;

    public static toForm(model: JobCasingEquipmentCP2Model): UntypedFormGroup {
        return new UntypedFormGroup({
            id: new UntypedFormControl(model.id),
            toolId: new UntypedFormControl(model.toolId),
            count: new UntypedFormControl(model.count),
            productCode: new UntypedFormControl(model.productCode),
            productName: new UntypedFormControl(model.productName),
            productGroup: new UntypedFormControl(model.productGroup),
            productFamily: new UntypedFormControl(model.productFamily),
            className: new UntypedFormControl(model.className),
            casingWeight: new UntypedFormControl(model.casingWeight),
            casingGrade: new UntypedFormControl(model.casingGrade),
            casingThread: new UntypedFormControl(model.casingThread),
            sapMaterialNumber: new UntypedFormControl(model.sapMaterialNumber),
            sapMaterialDescription: new UntypedFormControl(model.sapMaterialDescription),
            classDescription: new UntypedFormControl(model.classDescription),
            casingSize: new UntypedFormControl(model.casingSize),
            dataSource: new UntypedFormControl(model.dataSource),
            numberOfItems: new UntypedFormControl(model.numberOfItems),
            numberOfItemsShipped: new UntypedFormControl(model.numberOfItemsShipped),
            status: new UntypedFormControl(model.status),
            maxPressure: new UntypedFormControl(model.maxPressure),
            collapsePressure: new UntypedFormControl(model.collapsePressure),
            burstPressure: new UntypedFormControl(model.burstPressure),
            maxTemperature: new UntypedFormControl(model.maxTemperature),
            isMaxPressureNA: new UntypedFormControl(model.isMaxPressureNA),
            isMaxTemperatureNA: new UntypedFormControl(model.isMaxTemperatureNA),
            isNewlyAdded: new UntypedFormControl(model.isNewlyAdded)
        });
    }

    public static toModel(fg: UntypedFormGroup): JobCasingEquipmentCP2Model {
        const res: JobCasingEquipmentCP2Model = fg.getRawValue();
        return res;
    }
}
